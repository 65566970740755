<template>
    <Toast />

    <div class="card">
        <h5>My models</h5>

        <div class="grid">
            <div v-for="(model, modelIndex) in modelList" :key="modelIndex" class="col-4 md-4">
                <Toolbar>
                    <template #start>
                        {{ model.Name }}
                    </template>
                    <template #end>
                        <router-link :to="{ name: 'formulation', params: { module: model.Name, account: model.account } }"
                            v-slot="{ navigate }">
                            <Button label="Launch" @click="
                                SharedState.setModel(model.Name);
                            navigate();
                            " class="p-button-rounded p-button-raised p-button-warning p-mb-2" />
                        </router-link>
                    </template>
                </Toolbar>
                <div class="card">
                    {{ model.Description }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormulatorService from '../tipureServices/FormulatorService';
export default {
    inject: ['SharedState'],
    formulatorService: null,
    data() {
        return {
            modelList: null,
        };
    },
    adminService: null,
    created() {
        this.formulatorService = new FormulatorService();
    },
    mounted() {
        this.formulatorService
            .getUserPortfolio()
            .then((data) => {
                this.modelList = data['message'];
            })
            .catch((ex) => {
                console.log('ERROR loading portfolio');
                console.log(ex);
            });
    },
    methods: {},
};
</script>


<style></style>